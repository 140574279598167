
.product-box {
	width: 52px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	cursor: pointer;
	background-color: #ffffff;
 }
 
 .best-product-item {
	 background-color: #ffffff;
	 cursor: pointer;
	 border-radius: 10px;
	 padding: .5rem;
 }
 
 .best-product-item:hover {
	 background-color: #f5f5f5;
 }
 
 .product-box img {
	 width: 100%;
 }
 
 .best-product {
	 position: relative;
	 height: 460px;
 }
 

.chart-container1 {
    position: relative;
    height: 340px;
}

.gmaps, .gmaps-panaroma {
    height: 400px;
    background: #eee;
    border-radius: 3px;
}

.theme-icons {
    background-color: #fff;
    color: #434547;
}

.cursor-pointer {
    cursor: pointer;
}

.icon-badge {
    width: 45px;
    height: 45px;
    font-size: 1.2rem;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.product-img-2 {
    width: 45px;
    height: 45px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
}

.error-social a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 18px;
	color: #fff;
	text-align: center;
	border-radius: 50%;
	margin: 5px;
	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important
}


.productlist .product-box{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    border: 1px solid #e2e3e4;
    background-color: #ffffff;

}

.productlist .product-box img{
    width: 100%

}

.productlist .product-title {
    
    color: rgb(25, 27, 34);

}


.orderlist .product-box{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    border: 1px solid #e2e3e4;
    background-color: #ffffff;

}

.orderlist .product-box img{
    width: 100%

}

.orderlist .product-title {
    
    color: rgb(25, 27, 34);

}


#geographic-map {
    width: 100%;
    height: 400px;
}

.new-customer-list {
	position: relative;
	height: 500px
}

.new-customer-list .customer-list-item {
	padding: 0.5rem;
    border-radius: 10px;
}

.new-customer-list .customer-list-item:hover {
	background-color: #f0f0f0;
}


.top-sellers-list {
	position: relative;
	height: 460px
}

.top-sellers-list .sellers-list-item {
	padding: 0.5rem;
    border-radius: 10px;
}

.top-sellers-list .sellers-list-item:hover {
	background-color: #f0f0f0;
}


.client-message {
	position: relative;
	height: 565px
}

.client-message .client-messages-list {
	padding: 0.5rem;
}

.client-message .client-messages-list:hover {
	background-color: #f0f0f0;
}


.table-responsive {
    white-space: nowrap;
  }


  .project-user-groups img {
    margin-right: -14px;
    border: 1px solid #e4e4e4;
    padding: 2px;
    cursor: pointer;
}

.project-user-plus {
    width: 33px;
    height: 33px;
    margin-right: 0px;
    line-height: 33px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
    border: 1px dotted #a9b2bb;
    color: #404142;
}

.user-groups img {
    margin-left: -14px;
    border: 1px solid #e4e4e4;
    padding: 2px;
    cursor: pointer;
}
.user-plus {
    width: 33px;
    height: 33px;
    margin-left: -14px;
    line-height: 33px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
    border: 1px dotted #a9b2bb;
    color: #404142;
}
