
.back-to-top {
	display: none;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 26px;
	color: #fff;
	position: fixed;
	border-radius: 50%;
	bottom: 20px;
	right: 12px;
	background-color: #0d6efd;
	z-index: 5
}
.back-to-top:hover {
	color: #fff;
	background-color: #000;
	transition: all .5s
}