/* Responsive */

@media screen and (max-width:1280px) {
	.email-header {
		height: auto
	}
	.email-content {
		padding: 100px 0 0 0
	}
}



  @media screen and (max-width:1025px) {
      
    
    .sidebar-wrapper {
    
        left: -300px;
    }

  
    .page-content {

        margin-left: 0px;

    }

    .top-header .navbar{
      
        left: 0px;
    }

    /* Toggeled */


   .wrapper.nav-collapsed .sidebar-wrapper{
    
        left: 0px;
    }

    
    .wrapper.nav-collapsed .page-content {

        margin-left: 0px;

    }

    .wrapper.nav-collapsed .top-header .navbar{
      
        left: 0px;
    }


    .wrapper.nav-collapsed .overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #000;
		opacity: .6;
		z-index: 12;
		display: block;
		cursor: move;
		transition: all .2s ease-out
	}

    
.top-header .navbar .full-searchbar {

    position: absolute;
    top: 0%;
    left: 0;
    right: 0%;
    width: 100%;
    height: 60px;
    background-color: #f7f8fa;
    border-bottom: 1px solid rgb(0 0 0 / 0%);
    background-clip: padding-box;
    display: flex !important;
    z-index: 1;
    align-items: center;
    justify-content: start;
    padding: 0 1rem;
    -webkit-animation: .6s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 animdropdown;
	animation: .6s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 animdropdown
 
  }

.email-header {
    border-top-left-radius: .25rem
}
.email-sidebar {
    left: -280px
}
.email-content {
    margin-left: 0
}
.email-header {
    left: 0
}
.email-toggled .email-sidebar {
    left: 0
}
.email-toggled .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 250px;
    background: #000;
    opacity: .5;
    z-index: 9;
    display: block;
    cursor: move;
    transition: all .3s ease-out
}
   
.compose-mail-popup {
    width: auto;
    position: fixed;
    bottom: -30px;
    right: 0;
    left: 0
}

.chat-header {
    border-top-left-radius: .25rem
}
.chat-footer {
    border-bottom-left-radius: .25rem
}
.chat-sidebar {
    left: -370px
}
.chat-content {
    margin-left: 0
}
.chat-header {
    left: 0
}
.chat-footer {
    left: 0
}
.chat-toggled .chat-sidebar {
    left: 0
}
.chat-toggled .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 340px;
    background: #000;
    opacity: .5;
    z-index: 11;
    display: block;
    cursor: move;
    transition: all .3s ease-out
}



  
}


@media screen and (max-width:1024px) {
    .authentication-card {
        padding: 1.5rem 0;
        height: auto;
    }
}


  @media screen and (max-width:767px) {

	.top-header .navbar .dropdown-menu::after {
		display: none
	}

	.top-header .navbar .dropdown {
		position: static!important
	}

	.top-header .navbar .dropdown-menu {
		width: 100%!important;
	}



}


@media screen and (max-width:520px) {
	.chat-footer-menu,
	.chat-top-header-menu {
		display: none
	}
}