/* Authentication */

.authentication-card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 60rem;
    height: 100vh;
}

.login-separater span {
    position: relative;
    top: 26px;
    margin-top: -10px;
    background: #fff;
    padding: 5px;
    font-size: 12px;
    color: #cbcbcb;
    z-index: 1;
}

