.fm-menu .list-group a {
    font-size: 16px;
    color: #5f5f5f;
    display: flex;
    align-items: center;
}

.fm-menu .list-group a i {
    font-size: 23px;
}

.fm-menu .list-group a:hover {
	background-color: rgb(52 97 255 / 15%);
	color: #3461ff;
	transition: all .2s ease-out
}

.add-post {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.15);
}

.fm-icon-box {
    font-size: 32px;
    background: #fff;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-box {
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #c7c8cb;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
