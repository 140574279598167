/* Chips */

.chip {
    display: inline-block;
    height: 32px;
    padding: 0 12px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(0, 0, 0, .7);
    cursor: pointer;
    background-color: #f1f1f1;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 16px;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    box-shadow: none;
}

.chip img {
    float: left;
    width: 32px;
    height: 32px;
    margin: 0 8px 0 -12px;
    border-radius: 50%;
}

.profile-cover {
    background-image: linear-gradient(to bottom right, rgb(26 30 33 / 50%), rgb(0 0 0 / 50%)), url(/../../images/gallery/33.jpg);
    background-size: cover;
    height: 24rem;
    background-position: center;
    margin: -4.0rem -1.5rem -5.5rem -1.5rem;
    padding: 1.5rem 1.5rem 6.5rem 1.5rem;

}
