
html.semi-dark .sidebar-wrapper,
html.semi-dark .sidebar-wrapper .sidebar-header {

    background-color: #1a2232;

}


html.semi-dark .sidebar-wrapper, 
html.semi-dark .sidebar-wrapper .sidebar-header {

    border-right: 1px solid #1a2232;

}

html.semi-dark .top-header .navbar {
    background-color: #ffffff;
}

html.semi-dark .top-header .navbar .searchbar .form-control {
    border: 1px solid #e2e3e4;
    box-shadow: none;
}

html.semi-dark .mobile-toggle-icon, html.semi-dark .search-toggle-icon, html.semi-dark .projects, html.semi-dark .notifications, html.semi-dark .messages {
    border: 1px solid #e2e3e4;
    box-shadow: none;
}

html.semi-dark .top-header .navbar .user-setting {
    border: 1px solid #e2e3e4;
    box-shadow: none;
}

html.semi-dark .top-header .navbar .searchbar .form-control:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}


html.semi-dark .sidebar-wrapper .sidebar-header {
    border-bottom: 1px solid rgb(255 255 255 / 12%);
    color: #fcfcfc;
}


html.semi-dark .sidebar-wrapper .sidebar-header .logo-text,
html.semi-dark .sidebar-wrapper .sidebar-header .toggle-icon {
    color: #fcfcfc;
}


html.semi-dark .sidebar-wrapper .metismenu .mm-active>a, html.semi-dark .sidebar-wrapper .metismenu a:active, html.semi-dark .sidebar-wrapper .metismenu a:focus, html.semi-dark .sidebar-wrapper .metismenu a:hover {
    color: #fff;
    text-decoration: none;
    background: rgb(46 57 78);
}
html {
	&.semi-dark {
	 
	  .sidebar .navigation li {
		&.open > a {
		  color: #fff;
		  background: rgb(255 255 255 / 18%);
		}
  
		> a {
		  color: #9ea4aa;
		}
  
		&:hover > a, &.active > a {
		  color: #fff;
		  background: rgb(255 255 255 / 18%);
		}
  
		ul {
		  border: 1px solid #ffffff00;
		  background: rgb(255 255 255 / 8%);
  
		  li {
			&:hover > a, &.active > a {
			  color: #fff;
			  background: rgb(255 255 255 / 18%);
			}
		  }
		}
	  }
    }
}

html.semi-dark .sidebar-wrapper .metismenu a {
    color: #9ea4aa;
}

html.semi-dark .sidebar-wrapper .metismenu ul {
    border: 1px solid #ffffff00;
    background: rgb(32 42 64);
}

html.semi-dark .sidebar-wrapper .sidebar-header img {
    filter: invert(1) grayscale(100%) brightness(200%);
}

html.semi-dark .sidebar-wrapper .simplebar-scrollbar:before {
	background: rgba(255, 255, 255, .4)
}