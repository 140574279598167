.card {
  border: 0;
  margin-bottom: 1.5rem;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
}

.card-header {
background-color: rgb(255 255 255);
}

