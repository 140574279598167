
/* Tables */

.color-table .table {
	color: #ffffff;
	border-color: rgb(255 255 255 / 22%);
}

.info-table .table, .warning-table .table {
	color: #16191d;
	border-color: rgb(0 0 0 / 12%);
}