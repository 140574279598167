/* swicther */

.btn-switcher {
    position: fixed;
    right: 0px;
    top: 40%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}


.header-colors-indigators .indigator {
	width: 50px;
    height: 50px;
    background-color: #f7f8fa;
    border-radius: 10px;
    cursor: pointer;
}

