
/* Widgets */

.widget-icon {
    width: 48px;
    height: 48px;
    background-color: #fafafa;
    border-radius: 50%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.widget-icon-large {
    width: 54px;
    height: 54px;
    background-color: #fafafa;
    border-radius: 10px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}



.w_chart {
    position: relative;
    display: inline-block;
    width: 80px !important;
    height: 80px !important;
    text-align: center;
    font-size: 1.4em;
}

.w_percent {
    display: inline-block;
    line-height: 80px !important;
    z-index: 2;
    color: #0a0808;
}

.w_percent:after {
    content: '%';
    margin-left: 0.1em;
}

.w_chart canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px !important;
    height: 80px !important;
}
